import React from "react"
import Helmet from "react-helmet"
import Layout from "../templates/Layout"
import Container from "../components/Container"

import { withLoginStateContext } from "../contexes/LoginStateContext"

const Credits = () => (
  <Layout>
    <Container>
      <Helmet title="Credits" />
      <h1>About the material</h1>
      <p>
        The course was created by Keijo Heljanko using University of Helsinki{" "}
        <a
          href="https://www.helsinki.fi/en/researchgroups/data-driven-education"
          target="_blank"
          rel="noopener noreferrer"
        >
          Agile Education Research -research group
        </a>{" "}
        created course platform.
      </p>
      <h2>Material</h2>
      <p>
        The material was written by Keijo Heljanko. The material is licensed
        under{" "}
        <a
          href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Creative Commons BY-NC-SA 4.0.
        </a>{" "}
        You are allowed to distribute and modify the content as long as the
        identities of original authors are not removed. If you modify the
        content, you have to use the same license. Commercial use without a
        permit is forbidden.
      </p>
    </Container>
  </Layout>
)

export default withLoginStateContext(Credits)
